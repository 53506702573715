import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { hasAnyRole } from "@/utils/helpers";

const RedirectToWorkOrderList = () => {
  const userRoles = useSelector((state) => state.auth?.userRoles);

  return (
    <Navigate
      to={`/work-order/${hasAnyRole(userRoles, "isRequester") ? "request" : "pending"}`}
      replace
    />
  );
};

export default RedirectToWorkOrderList;
