import { allPrivateRoutes } from "../router";
import LazyLoadWrapper from "@/router/components/LazyLoadWrapper";
import RequiredAuth from "@/router/components/RequiredAuth";
import RouteGuard from "@/router/components/RouteGuard";

export const getAccessibleRoutes = (allPrivateRoutes, userRoles) => {
  const accessibleRoutes = allPrivateRoutes.filter(({ sidebarProps }) => {
    const { key, permissions, excludedPermissions } = sidebarProps || {};

    if (!key) return false;

    const hasPermission = permissions?.some((permission) =>
      userRoles.includes(permission)
    );
    if (!hasPermission) return false;

    if (excludedPermissions?.length) {
      const isExcluded = excludedPermissions.some((excludedPermission) =>
        userRoles.includes(excludedPermission)
      );
      if (isExcluded) return false;
    }

    return true;
  });

  return accessibleRoutes.sort((a, b) => {
    return (a.sidebarProps?.order || 0) - (b.sidebarProps?.order || 0);
  });
};

export const getFirstAccessibleRoute = (userRoles) => {
  const allAccessibleRoutes = getAccessibleRoutes(allPrivateRoutes, userRoles);

  return allAccessibleRoutes.length > 0
    ? allAccessibleRoutes[0].path
    : "error-page";
};

export const protectedRoute = (importFns, allowedRoles, excludedRoles) => {
  const createNestedComponent = (importFns, allowedRoles, excludedRoles) =>
    importFns.reduceRight(
      (acc, curr) => (
        <RequiredAuth allowedRoles={allowedRoles} excludedRoles={excludedRoles}>
          <LazyLoadWrapper importFn={curr}>{acc}</LazyLoadWrapper>
        </RequiredAuth>
      ),
      null
    );

  return (
    <RouteGuard mustAuthenticated>
      {createNestedComponent(importFns, allowedRoles, excludedRoles)}
    </RouteGuard>
  );
};
