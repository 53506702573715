import { protectedRoute } from "@/utils/routeUtils";
import RedirectToWorkOrderList from "../components/RedirectToWorkOrderList";
import RouteTypeValidationBasedOnRole from "../components/RouteTypeValidationBasedOnRole";

const moduleTitle = "module_title.work_order";
const allowedRoles = ["isRequester", "isReviewer"];
const allowedRoleWithType = [
  { role: ["isRequester"], type: ["request"] },
  { role: ["isReviewer"], type: ["pending", "history"] },
];

const workOrderRoutes = {
  path: "work-order",
  title: moduleTitle,
  sidebarProps: {
    key: "1-2",
    iconSrc: "work-order",
    label: moduleTitle,
    link: "/work-order",
    groupingType: "TOOLS",
    order: 2,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: <RedirectToWorkOrderList />,
    },
    {
      path: ":type",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          element: (
            <RouteTypeValidationBasedOnRole
              allowedRoleWithType={allowedRoleWithType}
              fallback={protectedRoute(
                [() => import("@/pages/work-order/WorkOrderList")],
                allowedRoles
              )}
            />
          ),
        },
        {
          path: ":id",
          title: moduleTitle,
          headerProps: {
            backUrl: "/work-order/:type",
          },
          element: (
            <RouteTypeValidationBasedOnRole
              allowedRoleWithType={allowedRoleWithType}
              fallback={protectedRoute(
                [() => import("@/pages/work-order/WorkOrderDetails")],
                allowedRoles
              )}
            />
          ),
        },
      ],
    },
  ],
};

export default workOrderRoutes;
