import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { authSelectors } from "@/store/slices/auth";
import { getFirstAccessibleRoute } from "@/utils/routeUtils";

const RawInitialRoute = ({ className }) => {
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const userRoles = useSelector((state) => state.auth.userRoles);

  if (!isLoggedIn) {
    return <Navigate to="/sign-in" replace />;
  }

  if (userRoles?.length > 0) {
    const firstAccessibleRoute = getFirstAccessibleRoute(userRoles);
    return <Navigate to={`/${firstAccessibleRoute}`} replace />;
  }

  return (
    <Spin
      spinning={true}
      size="large"
      indicator={<LoadingOutlined />}
      className={`${className} centered-spin`}
    />
  );
};

const InitialRoute = styled(RawInitialRoute)`
  &.centered-spin {
    top: 50%;
    left: 50%;
  }
`;

export default InitialRoute;
