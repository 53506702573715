import { Navigate } from "react-router-dom";
import { protectedRoute } from "@/utils/routeUtils";
import RouteTypeValidation from "../components/RouteTypeValidation";

const moduleTitle = "module_title.customer_invoice_information_sheet";
const allowedRoles = ["isApprover"];
const allowedTypes = ["pending", "history"];

const ciisRoutes = {
  path: "ciis",
  title: moduleTitle,
  sidebarProps: {
    key: "1-3",
    iconSrc: "ciis",
    label: "module_title.ciis",
    link: "/ciis",
    groupingType: "TOOLS",
    order: 3,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: <Navigate to="/ciis/pending" replace />,
    },
    {
      path: ":type",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          element: (
            <RouteTypeValidation
              allowedTypes={allowedTypes}
              fallback={protectedRoute(
                [() => import("@/pages/ciis/CIISList")],
                allowedRoles
              )}
            />
          ),
        },
        {
          path: ":id",
          title: moduleTitle,
          headerProps: {
            backUrl: "/ciis/:type",
          },
          element: (
            <RouteTypeValidation
              allowedTypes={allowedTypes}
              fallback={protectedRoute(
                [() => import("@/pages/ciis/CIISDetails")],
                allowedRoles
              )}
            />
          ),
        },
      ],
    },
  ],
};

export default ciisRoutes;
