import { useState, useEffect } from "react";
import { ArrowUpOutlined } from "@ant-design/icons";

const RawBackTopButton = ({ layoutContentRef }) => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const progressStyle = {
    position: "fixed",
    bottom: "25px",
    right: "27px",
    height: "54px",
    width: "54px",
    display: isVisible ? "grid" : "none",
    placeItems: "center",
    borderRadius: "50%",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    background: `conic-gradient(#D9D9D9 ${scrollProgress}%, #D9D9D980 ${scrollProgress}%)`,
    zIndex: 10,
  };

  const progressValueStyle = {
    height: "calc(100% - 8px)",
    width: "calc(100% - 8px)",
    backgroundColor: "#01010B",
    borderRadius: "50%",
    display: "grid",
    placeItems: "center",
  };

  useEffect(() => {
    const contentRef = layoutContentRef.current;
    if (!contentRef) return;

    contentRef.addEventListener("scroll", calcScrollValue);

    return () => {
      contentRef.removeEventListener("scroll", calcScrollValue);
    };
  }, [layoutContentRef]);

  const calcScrollValue = () => {
    if (!layoutContentRef.current) return;

    const contentRef = layoutContentRef.current;
    const scrollTop = contentRef.scrollTop;
    const scrollHeight = contentRef.scrollHeight;
    const clientHeight = contentRef.clientHeight;

    const scrollValue = Math.round(
      (scrollTop * 100) / (scrollHeight - clientHeight)
    );

    setScrollProgress(scrollValue);
    setIsVisible(scrollTop > 100);
  };

  const scrollToTop = () => {
    if (!layoutContentRef.current) return;

    layoutContentRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div id="progress" style={progressStyle} onClick={scrollToTop}>
      <span id="progress-value" style={progressValueStyle}>
        <ArrowUpOutlined style={{ fontSize: "22px", color: "#A1A9B8" }} />
      </span>
    </div>
  );
};

export default RawBackTopButton;
