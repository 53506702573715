import { Navigate } from "react-router-dom";
import { protectedRoute } from "@/utils/routeUtils";
import RedirectToAppEmployeeBasicInfo from "../components/RedirectToAppEmployeeBasicInfo";
import RouteTypeValidation from "../components/RouteTypeValidation";

const moduleTitle = "module_title.employee_approval_management";
const allowedRoles = ["isVisaApprover"];
const allowedTypes = ["approved", "rejected", "pending"];

const employeeApprovalRoutes = {
  path: "employee-approval",
  title: moduleTitle,
  sidebarProps: {
    key: "1-13",
    iconSrc: "employee-approval",
    label: "module_title.employee_approval",
    link: "/employee-approval",
    groupingType: "TOOLS",
    order: 13,
    permissions: allowedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: <Navigate to="/employee-approval/pending" replace />,
    },
    {
      path: ":type",
      title: moduleTitle,
      children: [
        {
          index: true,
          path: "",
          element: (
            <RouteTypeValidation
              allowedTypes={allowedTypes}
              fallback={protectedRoute(
                [
                  () => import("@/pages/employee-approval/EmployeeApproval"),
                  () =>
                    import(`@/pages/employee-approval/EmployeeApprovalList`),
                ],
                allowedRoles
              )}
            />
          ),
        },
        {
          path: ":id",
          title: moduleTitle,
          children: [
            {
              index: true,
              path: "",
              headerProps: { backUrl: "/employee-approval/:type" },
              element: <RedirectToAppEmployeeBasicInfo />,
            },
            {
              path: "employee-basic-info",
              title: moduleTitle,
              headerProps: { backUrl: "/employee-approval/:type" },
              element: (
                <RouteTypeValidation
                  allowedTypes={allowedTypes}
                  fallback={protectedRoute(
                    [
                      () =>
                        import(
                          "@/pages/employee-approval/EmployeeApprovalDetails"
                        ),
                      () =>
                        import(
                          "@/pages/employee-approval/employee-basic-info/EmpAppEmployeeBasicInfo"
                        ),
                    ],
                    allowedRoles
                  )}
                />
              ),
            },
            {
              path: "passport-visa-info",
              title: moduleTitle,
              headerProps: { backUrl: "/employee-approval/:type" },
              element: (
                <RouteTypeValidation
                  allowedTypes={allowedTypes}
                  fallback={protectedRoute(
                    [
                      () =>
                        import(
                          "@/pages/employee-approval/EmployeeApprovalDetails"
                        ),
                      () =>
                        import(
                          "@/pages/employee-approval/passport-visa-info/EmpAppPassportVisaInfo"
                        ),
                    ],
                    allowedRoles
                  )}
                />
              ),
            },
            {
              path: "spouse-visa-info",
              title: moduleTitle,
              headerProps: { backUrl: "/employee-approval/:type" },
              element: (
                <RouteTypeValidation
                  allowedTypes={allowedTypes}
                  fallback={protectedRoute(
                    [
                      () =>
                        import(
                          "@/pages/employee-approval/EmployeeApprovalDetails"
                        ),
                      () =>
                        import(
                          "@/pages/employee-approval/spouse-visa-info/EmpAppSpouseVisaInfo"
                        ),
                    ],
                    allowedRoles
                  )}
                />
              ),
            },
            {
              path: "dependant-visa-info",
              title: moduleTitle,
              headerProps: { backUrl: "/employee-approval/:type" },
              element: (
                <RouteTypeValidation
                  allowedTypes={allowedTypes}
                  fallback={protectedRoute(
                    [
                      () =>
                        import(
                          "@/pages/employee-approval/EmployeeApprovalDetails"
                        ),
                      () =>
                        import(
                          "@/pages/employee-approval/dependant-visa-info/EmpAppDependantVisaInfo"
                        ),
                    ],
                    allowedRoles
                  )}
                />
              ),
            },
            {
              path: "employment-info",
              title: moduleTitle,
              headerProps: { backUrl: "/employee-approval/:type" },
              element: (
                <RouteTypeValidation
                  allowedTypes={allowedTypes}
                  fallback={protectedRoute(
                    [
                      () =>
                        import(
                          "@/pages/employee-approval/EmployeeApprovalDetails"
                        ),
                      () =>
                        import(
                          "@/pages/employee-approval/employment-info/EmpAppEmploymentInfo"
                        ),
                    ],
                    allowedRoles
                  )}
                />
              ),
            },
          ],
        },
      ],
    },
  ],
};

export default employeeApprovalRoutes;
