import { protectedRoute } from "@/utils/routeUtils";

const profileRoute = {
  path: "profile",
  title: "module_title.edit_profile",
  element: protectedRoute(
    [() => import("@/pages/profile/Profile")],
    [
      "USER",
      "SUPER_ADMIN",
      "MANAGER",
      "OFFICER",
      "COMPANY_USER",
      "VISA_TEAM",
      "ONBOARDING_TEAM_USER",
      "isRequester",
      "isReviewer",
      "isApprover",
      "isVisaApprover",
    ]
  ),
};

export default profileRoute;
