import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useCustomMediaQuery } from "@/hooks";
import { useUserApi } from "@/api/user";
import { authActions, authSelectors } from "@/store/slices/auth";

const PublicLayout = () => {
  const { isMobile } = useCustomMediaQuery();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const profileUuid = useSelector((state) => state.auth.uuid);
  const { getUserProfile } = useUserApi();

  useEffect(() => {
    if (isLoggedIn) getProfile();
  }, [isLoggedIn]);

  const getProfile = async () => {
    try {
      const res = await getUserProfile(profileUuid);
      if (res.status !== "ok") return;

      const userMainRole = res.result?.userRole?.role?.code;
      const userSubRoles = res.result?.userRole
        ? Object.entries(res.result.userRole)
            .filter(
              ([key, value]) =>
                [
                  "isRequester",
                  "isReviewer",
                  "isApprover",
                  "isVisaApprover",
                ].includes(key) && value === true
            )
            .map(([key]) => key)
        : [];

      const userRoles = [userMainRole, ...userSubRoles];

      dispatch(authActions.setProfileObj(res.result));
      dispatch(authActions.setUserRoles(userRoles));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={isMobile ? "mobile" : ""}>
      <Outlet />
    </div>
  );
};

export default PublicLayout;
