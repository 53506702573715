import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  citizenshipList: [],
  isCitizenshipListLoading: false,
  countryList: [],
  isCountryListLoading: false,
  departmentList: [],
  isDepartmentListLoading: false,
  designationList: [],
  isDesignationListLoading: false,
  maritalStatusList: [],
  isMaritalStatusListLoading: false,
  nationalityList: [],
  isNationalityListLoading: false,
};

const genericRecordSlice = createSlice({
  name: "genericRecord",
  initialState,
  reducers: {
    setCitizenshipList(state, action) {
      state.citizenshipList = action.payload;
    },
    setIsCitizenshipListLoading(state, action) {
      state.isCitizenshipListLoading = action.payload;
    },
    setCountryList(state, action) {
      state.countryList = action.payload;
    },
    setIsCountryListLoading(state, action) {
      state.isCountryListLoading = action.payload;
    },
    setDepartmentList(state, action) {
      state.departmentList = action.payload;
    },
    setIsDepartmentListLoading(state, action) {
      state.isDepartmentListLoading = action.payload;
    },
    setDesignationList(state, action) {
      state.designationList = action.payload;
    },
    setIsDesignationListLoading(state, action) {
      state.isDesignationListLoading = action.payload;
    },
    setMaritalStatusList(state, action) {
      state.maritalStatusList = action.payload;
    },
    setIsMaritalStatusListLoading(state, action) {
      state.isMaritalStatusListLoading = action.payload;
    },
    setNationalityList(state, action) {
      state.nationalityList = action.payload;
    },
    setIsNationalityListLoading(state, action) {
      state.isNationalityListLoading = action.payload;
    },
  },
});

const allActions = genericRecordSlice.actions;

export const genericRecordActions = {
  ...allActions,
};

export default genericRecordSlice.reducer;
