import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoadingEmployeeBasicInfo: false,
  employeeBasicInfo: {},
  isEditing: false,
  newEmployeeId: "",
};

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setIsLoadingEmployeeBasicInfo(state, action) {
      state.isLoadingEmployeeBasicInfo = action.payload;
    },
    setEmployeeBasicInfo(state, action) {
      state.employeeBasicInfo = action.payload;
    },
    setIsEditing(state, action) {
      state.isEditing = action.payload;
    },
    setNewEmployeeId(state, action) {
      state.newEmployeeId = action.payload;
    },
  },
});

const allActions = employeeSlice.actions;

export const employeeActions = {
  ...allActions,
};

export default employeeSlice.reducer;
