import { forwardRef } from "react";
import { ReactSVG } from "react-svg";
import styled from "styled-components";

const RawSvgIcon = forwardRef(
  (
    {
      className,
      src,
      width,
      height,
      color,
      isCustomSrc = false,
      onClick = () => {},
    },
    ref
  ) => {
    const parsedSrc = isCustomSrc ? src : require(`@/assets/svgs/${src}.svg`);

    const setStyle = (svg) => {
      let styleAttribute = "";

      if (width) {
        styleAttribute += `width: ${width}px !important;`;
      }
      if (height) {
        styleAttribute += `height: ${height}px !important;`;
      }
      if (color) {
        styleAttribute += `color: ${color} !important;`;
      } else {
        styleAttribute += "color: var(--icon-default)";
      }

      svg.setAttribute("style", styleAttribute);
    };

    return (
      <div ref={ref} className={className} onClick={() => onClick()}>
        <ReactSVG src={parsedSrc} beforeInjection={setStyle} />
      </div>
    );
  }
);

const SvgIcon = styled(RawSvgIcon)`
  svg {
    display: block;
    margin: auto;
  }

  &.disabled {
    opacity: 0.3;
  }
  &.center-in-div {
    text-align: center;
    vertical-align: middle;
  }

  &.center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.rotate-anti-180 {
    transform: rotate(-180deg);
  }

  &.rotate-anti-90 {
    transform: rotate(-90deg);
  }

  &.rotate-90 {
    transform: rotate(90deg);
  }
`;

export default SvgIcon;
