// General
export const API_URL = process.env.REACT_APP_API_URL;
export const MOBILE_MAX_WIDTH = 992; // refer to Bootstrap's responsive design
export const PAGE_SIZE = [10, 20, 50, 100, 200];
export const TIME_FORMAT = "hh:mm A";
export const DATE_FORMAT = "DD/MM/YYYY";

// Ant Design UI
export const NOTIFY_POSITION = "bottomRight";
export const NOTIFY_DURATION = 5; // seconds

// Code for identification
export const DEFAULT_TIMEOUT = 120000; // 2mins
export const EXTENDED_TIMEOUT = 1200000; // 20mins
export const NO_TIMEOUT = 0;

// Localization
export const DEFAULT_LANG = "en";
export const LANGUAGE_OPTION = [
  {
    value: "en",
    label: "general.lang_en",
  },
  {
    value: "zh",
    label: "general.lang_zh",
  },
];

// Misc.
export const ALL_FILE_TYPE = {
  IMAGE: ".tif, .tiff, .bmp, .jpg, .jpeg, .gif, .png",
  EXCEL: ".csv, .xlsx, .xls",
  DOC: ".doc, .docx, .pdf",
};

export const ALL_FILE_TYPE_SVG = [
  "tif",
  "tiff",
  "bmp",
  "jpg",
  "jpeg",
  "gif",
  "png",
  "csv",
  "xlsx",
  "xls",
  "doc",
  "docx",
  "pdf",
];

export const ADD_STATUS = [
  {
    value: "ACTIVE",
    label: "status.active",
  },
  {
    value: "INACTIVE",
    label: "status.inactive",
  },
];

export const EDIT_STATUS = [
  {
    value: "ACTIVE",
    label: "status.active",
  },
  {
    value: "INACTIVE",
    label: "status.inactive",
  },
  {
    value: "ARCHIVED",
    label: "status.archived",
  },
];

export const EMPLOYEE_STATUS = [
  {
    value: "NEW",
    label: "status.new",
  },
  {
    value: "ONBOARDING",
    label: "status.onboarding",
  },
  {
    value: "ACTIVE",
    label: "status.active",
  },
  {
    value: "INACTIVE",
    label: "status.inactive",
  },
  {
    value: "ARCHIVED",
    label: "status.archived",
  },
];

export const EMPLOYMENT_STATUS = [
  {
    value: "DRAFT",
    label: "status.draft",
  },
  {
    value: "NEW",
    label: "status.new",
  },
  {
    value: "ACCEPTED",
    label: "status.accepted",
  },
  {
    value: "REJECTED",
    label: "status.rejected",
  },
  {
    value: "CONTRACT_SIGNED",
    label: "status.contract_signed",
  },
  {
    value: "RL_GEN",
    label: "status.rl_gen",
  },
  {
    value: "RL_SIGNED",
    label: "status.rl_signed",
  },
  {
    value: "RL_EMAILED",
    label: "status.rl_emailed",
  },
  {
    value: "PCEC_APPROVED",
    label: "status.pcec_approved",
  },
  {
    value: "LE_ISSUED",
    label: "status.le_issued",
  },
  {
    value: "HCU_EMAILED",
    label: "status.hcu_emailed",
  },
  {
    value: "PP_RECEIVED",
    label: "status.pp_received",
  },
  {
    value: "PP_STAMPED",
    label: "status.pp_stamped",
  },
];

export const DOCUMENT_STATUS = [
  {
    value: "NEW",
    label: "status.new",
  },
  {
    value: "ARCHIVED",
    label: "status.archived",
  },
  {
    value: "GENERATED",
    label: "status.generated",
  },
  {
    value: "REGENERATED",
    label: "status.regenerated",
  },
];

export const EMPLOYMENT_TERM = [
  {
    value: "THREE_MONTHS",
    label: "employee_shared.three_months",
  },
  {
    value: "SIX_MONTHS",
    label: "employee_shared.six_months",
  },
  {
    value: "TWELVE_MONTHS",
    label: "employee_shared.twelve_months",
  },
];

export const GENDER_LIST = [
  { label: "constant.male", value: "MALE" },
  { label: "constant.female", value: "FEMALE" },
];

export const PERSONAL_TITLE_LIST = [
  { label: "constant.mr", value: "MR" },
  { label: "constant.mrs", value: "MRS" },
  { label: "constant.ms", value: "MS" },
  { label: "constant.miss", value: "MISS" },
  { label: "constant.madam", value: "MADAM" },
];

export const LINGUISTIC_SKILLS_RATING = [
  { label: "constant.very_good", value: "VERY_GOOD" },
  { label: "constant.good", value: "GOOD" },
  { label: "constant.fair", value: "FAIR" },
];

export const GENERAL_SOFTWARE_LIST = [
  { label: "constant.ms_word", value: "MS_WORD" },
  { label: "constant.ms_excel", value: "MS_EXCEL" },
  { label: "constant.ms_powerpoint", value: "MS_POWERPOINT" },
  { label: "constant.email", value: "EMAIL" },
  { label: "constant.other", value: "OTHER" },
];

export const USER_SUB_ROLE = [
  { label: "user.requester", value: "requester" },
  { label: "user.reviewer", value: "reviewer" },
  { label: "user.approver", value: "approver" },
];

export const CHECK_NOTIFICATION_INTERVAL_IS_MS = 5000;

export const VISA_CATEGORY_LIST = [
  { label: "constant.board_of_investment", value: "BOARD_OF_INVESTMENT" },
  { label: "constant.public_sector", value: "PUBLIC_SECTOR" },
  { label: "constant.private_sector", value: "PRIVATE_SECTOR" },
  { label: "constant.student", value: "STUDENT" },
  { label: "constant.ex_sri_lankan", value: "EX_SRI_LANKAN" },
  { label: "constant.spouse", value: "SPOUSE" },
  { label: "constant.religious", value: "RELIGIOUS" },
  { label: "constant.registered_indian", value: "REGISTERED_INDIAN" },
  { label: "constant.diplomatic_missions", value: "DIPLOMATIC_MISSIONS" },
  {
    label: "constant.non_government_organisation",
    value: "NON_GOVERNMENT_ORGANISATION",
  },
  {
    label: "constant.inter_government_organisation",
    value: "INTER_GOVERNMENTAL_ORGANISATIONS",
  },
  { label: "constant.investor", value: "INVESTOR" },
  { label: "constant.expatriate", value: "EXPATRIATE" },
  { label: "constant.dependant", value: "DEPENDANT" },
  { label: "constant.other", value: "OTHER" },
];

export const LANDING_ENDORSEMENT_VISA_CATEGORY_LIST = [
  { label: "constant.investor", value: "INVESTOR" },
  { label: "constant.business", value: "BUSINESS" },
  { label: "constant.employee", value: "EMPLOYEE" },
  {
    label: "constant.cpc_residential_property_lessee",
    value: "CPC_RESIDENTIAL_PROPERTY_LESSEE",
  },
];

export const VISA_TYPE_LIST = [
  { label: "constant.employee", value: "EMPLOYEE" },
  { label: "constant.business", value: "BUSINESS" },
];

export const RESIDENT_VISA_FOR_LIST = [
  { label: "constant.first_issuance", value: "FIRST_ISSUANCE" },
  { label: "constant.extension", value: "EXTENSION" },
];

export const YEAR_OR_MONTH_OPTION = [
  { label: "constant.year", value: "YEAR" },
  { label: "constant.month", value: "MONTH" },
];
