// import moment from "moment"
/* eslint-disable */ // *** Disabled & fixed eslint rule : no-dupe-else-if

// ======================> HTML <======================
/**
 * Checks whether an HTML element has scrollable content.
 *
 * @param {HTMLElement} ele The HTML element to check for scrollability.
 * @returns {boolean} Returns true if the element has scrollable content, false otherwise.
 */

export function isScrollable(ele) {
  if (!ele) return false; // Ensure early return if element is not provided

  // Check if the element has scrollable content based on its dimensions
  const hasScrollableContent = ele.scrollHeight > ele.clientHeight;

  // Get the computed style of the element
  const computedStyle = window.getComputedStyle(ele);

  // Check if overflow-y is set to 'hidden' or 'scroll'
  const isOverflowHidden = computedStyle.overflowY === "hidden";
  const isOverflowScroll = computedStyle.overflowY === "scroll";

  // Return true if the element has scrollable content and overflow-y is not hidden
  return hasScrollableContent && !(isOverflowHidden || isOverflowScroll);
}

// ======================> List <======================
/**
 * Limits the number of decimal places in a given value.
 *
 * @param {number|string} value The value whose decimal places need to be limited.
 * @param {number} [decimalNumToLimit=2] The number of decimal places to limit the value to. Defaults to 2.
 * @returns {number} The value with the specified number of decimal places limited.
 * @throws {TypeError} If the input value is not a number or a string that can be converted to a number.
 */
export function limitDecimalNum(value, decimalNumToLimit = 2) {
  // Check if the input value is valid
  if (typeof value !== "number" && typeof value !== "string") {
    throw new TypeError(
      "Input value must be a number or a string that can be converted to a number"
    );
  }

  // Parse the value to a number
  const numericValue = Number(value);

  // Return 0 if the parsed value is not a valid number
  if (isNaN(numericValue)) {
    return 0;
  }

  // Return the rounded value with the specified number of decimal places
  return parseFloat(numericValue.toFixed(decimalNumToLimit));
}

/**
 * Splits an array into chunks of a specified size.
 *
 * @param {Array} array The array to split into chunks.
 * @param {number} chunkSize The size of each chunk.
 * @returns {Array} An array of chunks, where each chunk is an array of elements.
 * @throws {Error} If the input is not an array or the chunk size is less than or equal to zero.
 */
export function splitArrayToChunk(array, chunkSize) {
  // Check if the input is an array
  if (!Array.isArray(array)) {
    throw new Error("Input is not an array");
  }

  // Check if the chunk size is greater than zero
  if (chunkSize <= 0) {
    throw new Error("Chunk size must be greater than zero");
  }

  // Initialize an empty array to store the chunks
  const result = [];

  // Iterate over the input array and split it into chunks
  for (let i = 0; i < array.length; i += chunkSize) {
    // Slice the array to get a chunk of the specified size
    const chunk = array.slice(i, i + chunkSize);

    // Push the chunk into the result array
    result.push(chunk);
  }

  // Return the array of chunks
  return result;
}

// ======================> Validation <======================
export function validateEmail(email) {
  if (!email) return false;
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

/**
 * The function `isValidEmail` checks if a given value is a valid email address.
 * @param val - The `val` parameter represents the value that needs to be checked for email validity.
 * @returns The regular expression match result is being returned.
 */

export const isValidEmail = (val) => {
  if (!val) return;

  return String(val)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function validateNum(val) {
  if (!val) return false;
  return /^\d+$/.test(val);
}

// ======================> ETC <======================

// if navigator.clipboard not available
export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

// export function timeElapsed(date) {
//   const now = moment()
//   const timeStart = moment(date)
//   return moment.duration(timeStart.diff(now)).humanize()
// }

export function escapeHtml(unsafe) {
  return unsafe
    .replaceAll("&", "&amp;")
    .replaceAll("<", "&lt;")
    .replaceAll(">", "&gt;")
    .replaceAll('"', "&quot;")
    .replaceAll("'", "&#039;");
}

export function truncate(value, length) {
  if (value.length > length) {
    return value.substring(0, length) + "...";
  } else {
    return value;
  }
}

/**
 * Checks if one text contains another text, regardless of case.
 * @param {string} textA - The text to be searched.
 * @param {string} textB - The text to search for.
 * @returns {boolean} - True if textA contains textB, otherwise false.
 */
export const isTextContaining = (textA, textB) => {
  if (!textA || !textB) {
    throw new Error("Both textA and textB must be provided.");
  }

  const upperTextA = textA.toUpperCase();
  const upperTextB = textB.toUpperCase();

  return upperTextA.includes(upperTextB);
};

/**
 * Formats a size in bytes to a human-readable format with appropriate units.
 * @param {number} size - The size in bytes.
 * @returns {string} - The formatted size string with units (B, KB, MB, GB, TB).
 */
export const formatSize = (size) => {
  if (typeof size !== "number" || isNaN(size) || size < 0) {
    throw new Error("Invalid size. Size must be a non-negative number.");
  }

  const units = ["B", "KB", "MB", "GB", "TB"];
  let formattedSize = size;
  let unitIndex = 0;

  while (formattedSize >= 1000 && unitIndex < units.length - 1) {
    formattedSize /= 1024;
    unitIndex++;
  }

  return formattedSize.toFixed(2) + " " + units[unitIndex];
};

/**
 * Retrieves a nested property from an object based on a dot-separated property path.
 * @param {Object} obj - The object to fetch the property from.
 * @param {string} prop - The dot-separated property path.
 * @returns {*} - The value of the property if found, otherwise undefined.
 */
export const fetchFromObject = (obj, prop) => {
  // If the object is undefined or null, return undefined
  if (obj === undefined || obj === null) return undefined;

  // Index of the next property split
  const index = prop.indexOf(".");

  // Property split found; recursive call
  if (index > -1) {
    // Get object at property (before split), pass on remainder
    return fetchFromObject(
      obj[prop.substring(0, index)],
      prop.substr(index + 1)
    );
  }

  // No split; get property
  return obj[prop];
};

/**
 * The function `getNameFirstTwoInitial` takes a name as input and returns the first two initials of
 * the name in uppercase.
 * @param name - The `name` parameter is a string representing a person's full name.
 * @returns The function `getNameFirstTwoInitial` returns a string that consists of the first two
 * initials of a given name.
 */

export const getNameFirstTwoInitial = (name) => {
  if (!name) return "";

  const splitted = name.trim().split(/\s+/); // Split by any whitespace and remove extra spaces

  if (splitted.length === 1) {
    return splitted[0][0] ? splitted[0][0].toUpperCase() : ""; // Handle case where name[0] might not exist
  } else {
    const firstInitial = splitted[0][0] ? splitted[0][0].toUpperCase() : "";
    const secondInitial = splitted[1][0] ? splitted[1][0].toUpperCase() : "";
    return firstInitial + secondInitial;
  }
};

/**
 * The function `getFilterableSelectProps` returns a set of properties to be used with an Ant Design Select component
 * to enable searchable and filterable options within the dropdown.
 *
 * @returns {object} An object containing properties to enable search functionality, custom option filtering, and sorting in an Ant Design Select component.
 *
 * Properties:
 * - `showSearch`: Enables the search input in the Select dropdown.
 * - `popupMatchSelectWidth`: Disables matching the popup width to the Select width.
 * - `filterOption`: A function to filter options based on the search input. It converts both the input and option labels to lowercase and checks for inclusion.
 * - `filterSort`: A function to sort options. It handles sorting based on the following logic:
 *   - Options with the `key` of `-1` (reserved for "All") are excluded from sorting and retain their position.
 *   - If both options have an `orderSeq` property with a value greater than `0`, sorting is done based on `orderSeq`.
 *   - For other options, sorting is performed alphabetically by their labels (case-insensitive).
 */
export const getFilterableSelectProps = () => {
  return {
    showSearch: true,
    popupMatchSelectWidth: false,
    filterOption: (input, option) =>
      (option?.label?.toLowerCase() ?? "").includes(input.toLowerCase()),
    filterSort: (a, b) => {
      // Exclude "All" (key = -1) from sorting
      if (a.key === -1 || b.key === -1) return 0;

      if (a.orderSeq > 0 && b.orderSeq > 0) {
        return a.orderSeq - b.orderSeq;
      } else {
        return (a?.label ?? "")
          .toLowerCase()
          .localeCompare((b?.label ?? "").toLowerCase());
      }
    },
  };
};

/**
 * The function `formatGenericRecordOptions` formats a list of records into options for a select input.
 *
 * @param {Array} list - List of records with `id`, `name`, and `code` properties.
 * @param {string} labelKey - Determines how the `label` is constructed. If "code", includes both name and code.
 * @returns {Array} List of formatted option objects. Returns an empty array if the input list is empty or not provided.
 */
export const formatGenericRecordOptions = (list, labelKey) =>
  list
    ?.filter((el) => el.id !== 0)
    .map((el) => ({
      key: el.id,
      value: el.id,
      label:
        labelKey === "code"
          ? `${el.name} (${el.code})`
          : labelKey === "currency"
            ? `${el.code} ${el.name}`
            : el.name,
      code: el.code,
    })) || [];

/**
 * The function `formatNumber` formats a given numeric value to a string with a specified number of decimal places.
 * It also adds commas to the integer part of the number for better readability.
 * @param {number|string} value - The numeric value to be formatted. This can be a number or a string representation of a number.
 * @param {number} [decimalPlaces=0] - The number of decimal places to include in the formatted output. Defaults to 0 if not specified.
 * @returns {string} The formatted number as a string. If the input value is not provided or is invalid, an empty string is returned.
 */
export const formatNumber = (value, decimalPlaces = 0) => {
  if (value === 0) return 0;
  if (!value) return "-";

  const numberValue = parseFloat(value).toFixed(decimalPlaces);
  const [integerPart, decimalPart] = numberValue.split(".");
  const formattedIntegerPart = Number(integerPart).toLocaleString();

  return decimalPlaces > 0
    ? `${formattedIntegerPart}.${decimalPart}`
    : formattedIntegerPart;
};

/**
 * The function `handleInputBlur` trims the whitespace from the value of an Ant Design Input component
 * and sets the trimmed value in the form.
 * @param {object} form - The form instance from Ant Design.
 * @param {string} fieldName - The name of the field in the form to set the trimmed value.
 * @returns {function} A function to handle the blur event of an Ant Design Input component.
 */
export const handleInputBlur = (form, fieldName) => (event) => {
  const { value } = event.target;
  const trimmedValue = value.trim();

  const updatedValues = Array.isArray(fieldName)
    ? fieldName.reduceRight((acc, key) => ({ [key]: acc }), trimmedValue)
    : { [fieldName]: trimmedValue };

  form.setFieldsValue(updatedValues);
};

/**
 * The function `trimInputNumberValue` trims the whitespace from a given number value.
 * @param {number|string} value - The numeric value to be trimmed. This can be a number or a string representation of a number.
 * @returns {string} The trimmed numeric value as a string. If the input value is not provided or is invalid, an empty string is returned.
 */
export const trimInputNumberValue = (value) => {
  if (!value) return "";

  const trimmedValue = value.toString().trim();
  return trimmedValue;
};

/**
 * The function `formatInputNumber` formats a given numeric value to a string with comma-separated thousands.
 * @param {number|string} value - The numeric value to be formatted. This can be a number or a string representation of a number.
 * @returns {string} The formatted number as a string. If the input value is not provided or is invalid, an empty string is returned.
 */
export const formatInputNumber = (value) => {
  if (!value) return "";

  const numberValue = parseFloat(value);
  const formattedNumber = numberValue.toLocaleString();
  return formattedNumber;
};

/**
 * The function `removeNumberFormatting` removes any formatting (such as commas and currency symbols) from a numeric string.
 * @param {string} value - The formatted numeric string to be sanitized.
 * @returns {string} The sanitized numeric string with formatting removed. If the input value is not provided or is invalid, an empty string is returned.
 */
export const removeNumberFormatting = (value) => {
  if (!value) return "";

  const sanitizedNumber = value.replace(/\$\s?|(,*)/g, "");
  return sanitizedNumber;
};

/**
 * The function `formatBigFigure` converts a numeric value into a more readable format using suffixes for thousands (K), millions (M), and billions (B).
 * It simplifies large numbers for better readability and understanding, allowing for two different formatting styles: SI unit notation or scientific notation.
 *
 * @param {number} value - The numeric value to be formatted. This can be any number, including positive and negative values.
 * @param {boolean} isSiUnit - A flag indicating whether to use SI unit notation (e.g., "K", "M", "B") or scientific notation (e.g., "x10³", "x10⁶", "x10⁹").
 * @returns {string} A formatted string representing the input value with an appropriate suffix:
 * - "K" for thousands (1,000)
 * - "M" for millions (1,000,000)
 * - "B" for billions (1,000,000,000)
 * If the value is less than 1,000, it returns the original number as a string.
 **/
export const formatBigFigure = (value, isSiUnit = false) => {
  if (typeof value === "string") {
    value = +value.replace(/,/g, "");
  }

  if (Math.abs(value) >= 1e9) {
    // For billions
    return (value / 1e9).toFixed(1) + (isSiUnit ? " B" : "x10⁹");
  } else if (Math.abs(value) >= 1e6) {
    // For millions
    return (
      (value / 1e6).toFixed(1).replace(/\.0$/, "") + (isSiUnit ? " M" : "x10⁶")
    );
  } else if (Math.abs(value) >= 1e3) {
    // For thousands
    return (
      (value / 1e3).toFixed(1).replace(/\.0$/, "") + (isSiUnit ? " K" : "x10³")
    );
  } else {
    return value;
  }
};

/**
 * Formats the input text so that the first letter of each word is uppercase, and all other letters are lowercase.
 * Commonly used to convert text to Title Case (e.g., "MY NAME" becomes "My Name").
 *
 * @param {string} text - The text to format.
 * @returns {string} - The formatted text in Title Case.
 */
export const toTitleCase = (text) => {
  if (typeof text !== "string" || text.trim() === "") return "-";

  return text
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

/**
 * Converts a backend constant string (e.g., "BACKEND_CONSTANT_STRING")
 * into a more readable format by replacing underscores with spaces
 * and capitalizing the first letter of each word.
 *
 * Commonly used to convert constant strings to a user-friendly display format
 * (e.g., "BACKEND_CONSTANT_STRING" becomes "Backend Constant String").
 *
 * @param {string} string - The backend constant string to format.
 * @returns {string} - The formatted string in Title Case with spaces.
 */
export const parseBackendConstantString = (string) => {
  if (!!string) {
    return string
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
};

/**
 * Merges nested fields from existing data and parsed data into the payload object.
 * For each field in the `fields` array, this function checks if the field exists in `existingData`.
 * If it exists, the field's values from `existingData` and `parsedData` are merged.
 * If it doesn't exist, the field's value is taken directly from `parsedData`.
 *
 * @param {Object} existingData - The original data source containing existing field values.
 * @param {Object} parsedData - The new parsed data to be merged into the payload.
 * @param {string[]} fields - An array of field names (keys) to process for merging.
 * @param {Object} payload - The object where the merged fields will be added.
 * @returns {void} - Modifies the `payload` object in place, adding the merged fields.
 */
export const mergeNestedFields = (
  existingData,
  parsedData,
  fields,
  payload
) => {
  fields.forEach((field) => {
    const existingField = existingData?.[field];
    const parsedField = parsedData?.[field];

    if (!!existingField) {
      payload[field] = { ...existingField, ...parsedField };
    } else {
      payload[field] = { ...parsedField };
    }
  });
};

/**
 * Checks if the user has any of the specified roles.
 *
 * @param {string[]} userRoles - The list of roles assigned to the user.
 * @param {string[]} rolesToVerify - The list of roles to check against the user's roles.
 * @returns {boolean} - Returns `true` if the user has at least one of the roles in `rolesToVerify`, otherwise `false`.
 */
export const hasAnyRole = (userRoles, rolesToVerify) => {
  return userRoles.some((userAssignedRole) =>
    rolesToVerify.includes(userAssignedRole)
  );
};
