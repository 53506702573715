import { protectedRoute } from "@/utils/routeUtils";

const moduleTitle = "module_title.expatriate_document";
const allowedRoles = ["VISA_TEAM"];
const excludedRoles = ["isVisaApprover"];

const workOrderRoutes = {
  path: "expatriate-document",
  title: moduleTitle,
  sidebarProps: {
    key: "1-14",
    iconSrc: "expatriate-doc",
    label: moduleTitle,
    link: "/expatriate-document",
    groupingType: "TOOLS",
    order: 14,
    permissions: allowedRoles,
    excludedPermissions: excludedRoles,
  },
  element: null,
  children: [
    {
      index: true,
      path: "",
      title: moduleTitle,
      element: protectedRoute(
        [() => import("@/pages/expatriate-document/ExpatriateDocList")],
        allowedRoles,
        excludedRoles
      ),
    },
    {
      path: ":id",
      title: moduleTitle,
      headerProps: {
        backUrl: "/expatriate-document",
      },
      element: protectedRoute(
        [() => import("@/pages/expatriate-document/ExpatriateDocDetails")],
        allowedRoles,
        excludedRoles
      ),
    },
  ],
};

export default workOrderRoutes;
