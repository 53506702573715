import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { hasAnyRole } from "@/utils/helpers";

const RouteTypeValidationBasedOnRole = ({ allowedRoleWithType, fallback }) => {
  const { type } = useParams();
  const userRoles = useSelector((state) => state.auth?.userRoles);

  const normalizedType = type ? type.toLowerCase() : null;

  const userAllowedTypes = allowedRoleWithType.reduce((acc, item) => {
    if (hasAnyRole(userRoles, item.role)) {
      return [...acc, ...item.type];
    }
    return acc;
  }, []);

  const hasAllowedRoleWithType = userAllowedTypes.includes(normalizedType);

  if (userRoles?.length > 0 && !hasAllowedRoleWithType) {
    return <Navigate to="/error-page" replace />;
  }

  return fallback;
};

export default RouteTypeValidationBasedOnRole;
