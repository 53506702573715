import { useState, useEffect } from "react";
import { Image, Form, Input, Button, Spin, App, Flex } from "antd";
import {
  EyeInvisibleOutlined,
  EyeFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import styled from "styled-components";
import SvgIcon from "@/components/SvgIcon";
import {
  requiredRules,
  emailRules,
  passwordRules,
  confirmPasswordRules,
} from "@/constants/AntdValidatorRules";
import { useAuthApi } from "@/api/auth";
import { useUserApi } from "@/api/user";
import { authActions } from "@/store/slices/auth";
import { useDispatch } from "react-redux";
import SignInImage from "@/assets/images/sign-in.png";
import ForgetPasswordImage from "@/assets/images/forget-password.png";
import EmailSentMessageImage from "@/assets/images/email-sent-message.png";
import ChangePasswordImage from "@/assets/images/change-password.png";
import NewPasswordUpdatedImage from "@/assets/images/new-password-updated.png";
import { useTranslation } from "react-i18next";

const RawSignInPage = ({ className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStage, setCurrentStage] = useState("login"); // login, forgot-password, email-sent-message, change-password, updated-password
  const [loggedIn, setLoggedIn] = useState(false);

  const [form] = Form.useForm();
  const { notification } = App.useApp();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const backToLoginBtnStyle =
    currentStage !== "updated-password" ? "secondary" : "primary";

  const { login, resetPassword, changePassword } = useAuthApi();
  const { getUserProfile } = useUserApi();

  const searchParams = new URLSearchParams(window.location.search);
  const emailQuery = searchParams.get("email");
  const otpQuery = searchParams.get("otp");

  useEffect(() => {
    if (emailQuery && otpQuery) {
      setCurrentStage("change-password");
    }
  }, []);

  const onSubmit = (inputs) => {
    if (currentStage === "login") {
      doLogin(inputs);
    } else if (currentStage === "forgot-password") {
      doResetPassword(inputs);
    } else if (currentStage === "change-password") {
      doChangePassword(inputs);
    }
  };

  const doLogin = async (inputs) => {
    try {
      setIsLoading(true);

      const res = await login(inputs);
      if (res.status !== "ok") return;

      const token = res.result.token;
      const uuid = res.result.uuid;

      setLoggedIn(true);
      dispatch(authActions.setUuid(uuid));
      dispatch(authActions.setToken(token));
      await getProfile(uuid);

      notification.success({
        message: t("notification.login_successfully"),
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getProfile = async (uuid) => {
    try {
      const res = await getUserProfile(uuid);
      if (res.status !== "ok") return;

      const userMainRole = res.result?.userRole?.role?.code;
      const userSubRoles = res.result?.userRole
        ? Object.entries(res.result.userRole)
            .filter(
              ([key, value]) =>
                [
                  "isRequester",
                  "isReviewer",
                  "isApprover",
                  "isVisaApprover",
                ].includes(key) && value === true
            )
            .map(([key]) => key)
        : [];

      const userRoles = [userMainRole, ...userSubRoles];

      dispatch(authActions.setProfileObj(res.result));
      dispatch(authActions.setUserRoles(userRoles));
    } catch (error) {
      console.error(error);
    }
  };

  const emailFormItem = () => {
    return (
      <Form.Item
        label={t("sign_in.email")}
        name="email"
        rules={[...requiredRules(), ...emailRules()]}
        hasFeedback
      >
        <Input
          autoFocus
          placeholder=""
          allowClear={{
            clearIcon: <SvgIcon src="cross" height="24" width="24" />,
          }}
        />
      </Form.Item>
    );
  };

  const passwordFormItem = () => {
    return (
      <Form.Item
        label={t("sign_in.password")}
        name="password"
        rules={[...requiredRules(), ...passwordRules()]}
        hasFeedback
      >
        <Input.Password
          placeholder=""
          iconRender={(visible) =>
            visible ? <EyeFilled /> : <EyeInvisibleOutlined />
          }
          allowClear={{
            clearIcon: <SvgIcon src="cross" height="24" width="24" />,
          }}
        />
      </Form.Item>
    );
  };

  const confirmPasswordFormItem = () => {
    return (
      <Form.Item
        label={t("sign_in.confirm_password")}
        name="confirmPassword"
        rules={[...requiredRules(), ...confirmPasswordRules()]}
        dependencies={["password"]}
        hasFeedback
      >
        <Input.Password
          placeholder=""
          iconRender={(visible) =>
            visible ? <EyeFilled /> : <EyeInvisibleOutlined />
          }
        />
      </Form.Item>
    );
  };

  const actionFormItems = () => {
    const submit = (
      <Button className="custom-button primary w-100" htmlType="submit">
        {currentStage === "login" && t("button.log_in")}
        {currentStage === "forgot-password" && t("button.send")}
        {currentStage === "change-password" && t("button.submit")}
      </Button>
    );

    const forgotPassword = (
      <Button
        className="custom-button secondary w-100"
        onClick={() => setCurrentStage("forgot-password")}
      >
        {t("sign_in.forgot_password")}
      </Button>
    );

    const backToLogin = (
      <Button
        className={"custom-button " + backToLoginBtnStyle}
        block
        type="text"
        size="large"
        onClick={() => setCurrentStage("login")}
      >
        {t("button.back_to_login")}
      </Button>
    );

    return (
      <Form.Item className="submit-section">
        {currentStage === "login" && (
          <Flex vertical gap={20}>
            {submit}
            {forgotPassword}
          </Flex>
        )}

        {currentStage === "forgot-password" && (
          <Flex vertical gap={20}>
            {submit}
            {backToLogin}
          </Flex>
        )}

        {currentStage === "email-sent-message" && backToLogin}

        {currentStage === "change-password" && (
          <Flex vertical gap={20}>
            {submit}
            {backToLogin}
          </Flex>
        )}

        {currentStage === "updated-password" && backToLogin}
      </Form.Item>
    );
  };

  const doResetPassword = async (inputs) => {
    try {
      setIsLoading(true);
      let email = inputs?.email || form.getFieldValue("email");

      const res = await resetPassword({ email });
      if (res.status !== "ok") return;

      setCurrentStage("email-sent-message");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const doChangePassword = async (inputs) => {
    try {
      setIsLoading(true);

      const res = await changePassword({
        newPassword: inputs.password,
        email: emailQuery,
        otp: otpQuery,
      });
      if (res.status !== "ok") return;

      notification.success({
        message: t("notification.change_password_successfully"),
      });

      form.resetFields();
      setCurrentStage("updated-password");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const titleRow = () => (
    <>
      {currentStage === "login" && (
        <>
          <h1 className="first-subtitle">{t("sign_in.first_subtitle")}</h1>
          <p className="second-subtitle">{t("sign_in.second_subtitle")}</p>
        </>
      )}
      {currentStage === "forgot-password" && (
        <>
          <h1 className="first-subtitle">
            {t("sign_in.forgot_password_title")}
          </h1>
          <p className="second-subtitle">{t("sign_in.fill_in_email")}</p>
        </>
      )}
      {currentStage === "email-sent-message" && (
        <>
          <h1 className="first-subtitle">{t("sign_in.check_inbox")}</h1>
          <p className="second-subtitle">{t("sign_in.check_inbox_subtitle")}</p>
        </>
      )}
      {currentStage === "change-password" && (
        <>
          <h1 className="first-subtitle">
            {t("sign_in.reset_password_title")}
          </h1>
          <p className="second-subtitle">{t("sign_in.enter_a_new_password")}</p>
        </>
      )}
      {currentStage === "updated-password" && (
        <>
          <h1 className="first-subtitle">
            {t("sign_in.new_password_updated")}
          </h1>
          <p className="second-subtitle">
            {t("sign_in.new_password_updated_subtitle")}
          </p>
        </>
      )}
    </>
  );

  return (
    <Spin spinning={isLoading} size="large" indicator={<LoadingOutlined />}>
      <Flex
        align="center"
        justify="center"
        className={className + " sign-in-page"}
      >
        <div className="left-panel-block">
          <Flex vertical className="left-panel-content">
            <Flex align="center" gap={10} className="title-container">
              <SvgIcon src="logo" />
              <h3 className="title">{t("sign_in.title")}</h3>
            </Flex>
            {titleRow()}

            {loggedIn && (
              <Button className="custom-button primary w-100" loading>
                {t("sign_in.logging_you_in")}
              </Button>
            )}

            {!loggedIn && (
              <Form
                form={form}
                name="login"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={onSubmit}
                preserve={true}
                layout="vertical"
              >
                <div className={currentStage + "-form"}>
                  {currentStage !== "change-password" &&
                    currentStage !== "updated-password" &&
                    currentStage !== "email-sent-message" &&
                    emailFormItem()}

                  {(currentStage === "login" ||
                    currentStage === "change-password") &&
                    passwordFormItem()}

                  {currentStage === "change-password" &&
                    confirmPasswordFormItem()}
                </div>

                {actionFormItems()}
              </Form>
            )}
          </Flex>
        </div>
        <div className="right-panel-block">
          {currentStage === "login" && (
            <Image preview={false} src={SignInImage} />
          )}
          {currentStage === "forgot-password" && (
            <Image preview={false} src={ForgetPasswordImage} />
          )}
          {currentStage === "email-sent-message" && (
            <Image preview={false} src={EmailSentMessageImage} />
          )}
          {currentStage === "change-password" && (
            <Image preview={false} src={ChangePasswordImage} />
          )}
          {currentStage === "updated-password" && (
            <Image preview={false} src={NewPasswordUpdatedImage} />
          )}
        </div>
      </Flex>
    </Spin>
  );
};

let SignInPage = styled(RawSignInPage)`
  height: 100vh;

  .left-panel-block {
    width: 60%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .left-panel-content {
      color: #fbfbfb;
      width: 28rem;

      .title-container {
        margin-bottom: 4.5rem;
      }
      .title {
        margin-bottom: 0;
        font: normal normal normal 20px var(--poppins-semi-bold-font);
      }
    }
    .first-subtitle {
      font: normal normal normal 30px var(--poppins-medium-font);
      margin-bottom: 0;
    }
    .second-subtitle {
      font: normal normal normal var(--font-size-l) var(--poppins-regular-font);
      color: #8692a6;
      margin-bottom: 3.5rem;
    }
    .ant-form-item {
      &-label {
        height: 3rem !important; // Form Label and Input must same value
      }
      &:has(.ant-input:focus),
      &:has(.ant-input:not(:placeholder-shown)) {
        .ant-form-item-label {
          margin-top: -1.1rem !important;
        }
      }
    }
    .login-form,
    .change-password-form {
      margin-bottom: 3.25rem !important;
    }
    .forgot-password-form {
      margin-bottom: 6.25rem !important;
    }
    .email-sent-message-form,
    .updated-password-form {
      margin-bottom: 13.5rem !important;
    }
    .ant-input {
      height: 3rem !important; // Form Label and Input must same value

      &-affix-wrapper {
        padding: 0 1rem;
      }

      &-password-icon,
      &-password-icon:hover {
        color: #fbfbfb;
      }
      &-suffix {
        .ant-form-item-feedback-icon {
          margin-inline: 0.2rem;
        }
        .ant-input-clear-icon {
          order: 3;
          margin: 0;
        }
        .ant-input-clear-icon-hidden {
          visibility: unset;
          display: none;
        }
      }
    }
    .ant-space {
      &-gap-row-small {
        row-gap: 1.2rem;
      }
    }
    .ant-btn {
      height: 3rem;
    }
  }

  .right-panel-block {
    width: 40%;
    height: 100vh;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;

    .ant-image {
      width: 100%;
      height: 100%;

      &-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
`;

export default SignInPage;
